import React, { useState } from 'react';
import './App.css';
import NewsPage from './NewsPage';
import { 
  Mail, 
  X, 
  Map, 
  Music, 
  Newspaper, 
  BarChart, 
  ShoppingCart, 
  Settings,
  AlertTriangle, 
  Siren, 
  MapPin, 
  Phone,
  HelpCircle,
  Home,
  Shield,
  Clock,
  Battery,
  Vote
} from 'lucide-react';

// Simple wrapper without any default styles

const TeslaInCarDashboard = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [currentTime, setCurrentTime] = useState(new Date());

  React.useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  const handleModalOpen = (content) => {
    switch(content) {
      case 'News':
        setModalContent(<NewsPage />);
        break;
      case 'Statistics':
        setModalContent(<div>Statistics Content</div>); // Replace with actual Statistics component
        break;
      case 'Shop':
        setModalContent(<div>Shop Content</div>); // Replace with actual Shop component
        break;
      case 'Settings':
        setModalContent(<div>Settings Content</div>); // Replace with actual Settings component
        break;
      default:
        setModalContent(<div>Content for {content}</div>);
    }
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const externalLinks = [
    { label: 'Outlook', url: 'https://www.outlook.com/' , icon: Mail },
    { label: 'X.com', url: 'https://www.x.com/' , icon: X },
    { label: 'Maps', url: 'https://abetterrouteplanner.com/' , icon: Map },
    { label: 'Music', url: 'https://music.youtube.com/' , icon: Music },
  ];

  const internalPages = [
    { label: 'News', icon: Newspaper },
    { label: 'Statistics', icon: BarChart },
    { label: 'Shop', url:'https://www.tesland.com/en' , icon: ShoppingCart },
    { label: 'Settings', icon: Settings }
  ];

  const emergencyFunctions = [
    { label: 'Report Incident', icon: AlertTriangle },
    { label: 'SOS', icon: Siren },
    { label: 'Share Location', icon: MapPin },
    { label: 'Emergency Contacts', icon: Phone }
  ];

  const miscFunctions = [
    { label: 'Vote!', icon: Vote },
    { label: 'TBD 2', icon: HelpCircle },
    { label: 'TBD 3', icon: HelpCircle },
    { label: 'TBD 4', icon: HelpCircle }
  ];

  return (
    <div className="h-screen bg-gradient-to-br from-gray-900 to-blue-900 overflow-hidden flex flex-col">
      <header className="bg-black bg-opacity-30 p-2 grid grid-cols-5 items-center text-white">
        <button className="flex flex-col items-center justify-center hover:text-cyan-400 transition-colors">
          <Home className="w-6 h-6" />
          <span className="text-xs mt-1">Home</span>
        </button>

        <div className="flex flex-col items-center justify-center">
          <Clock className="w-6 h-6 text-cyan-400" />
          <span className="text-xs mt-1">
            {currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' , hour12: false })}
          </span>
        </div>

        <div className="flex flex-col items-center justify-center">
          <span className="text-xl font-bold text-cyan-400">Tesla</span>
          <span className="text-xs">Dashboard</span>
        </div>

        <div className="flex items-center justify-center space-x-2">
          <Battery className="w-6 h-6 text-green-400" />
          <Shield className="w-6 h-6 hover:text-cyan-400 transition-colors" />
        </div>

        <div className="flex flex-col items-center justify-center text-gray-400">
          <span className="text-xs">v1.0.1</span>
          <span className="text-xs text-green-400">Connected to Tesla API</span>
        </div>
      </header>

      <div className="flex-grow grid grid-cols-4 grid-rows-4 gap-4 p-4">
        {[...externalLinks, ...internalPages, ...emergencyFunctions, ...miscFunctions].map((item, index) => {
          const IconComponent = item.icon;
          return (
            <button 
              key={index} 
              onClick={() => {
                if (item.url) {
                  window.open(item.url, '_blank');
                } else {
                handleModalOpen(item.label);}
              }}
              
                className="
                bg-black bg-opacity-50 
                backdrop-blur-lg 
                border border-cyan-600 
                rounded-xl 
                flex 
                flex-col 
                justify-center 
                items-center 
                text-white 
                transition-all 
                duration-300 
                hover:scale-105 
                hover:bg-opacity-70 
                hover:shadow-lg 
                hover:shadow-cyan-500/50
                p-4
              "
            >
              <IconComponent className="w-12 h-12 text-cyan-400 mb-2" />
              <div className="text-sm text-center">{item.label}</div>
            </button>
          );
        })}
      </div>

      {openModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
    <div className="bg-gray-800 p-6 rounded-xl text-white relative w-3/4 max-h-[80vh] overflow-auto">
      {/* Render the modalContent component directly */}
      {modalContent}
      <button 
        onClick={handleModalClose}
        className="absolute top-4 right-4 bg-cyan-600 text-white px-4 py-2 rounded hover:bg-cyan-700"
      >
        Fuckit
      </button>
    </div>
  </div>
)}
    </div>
  );
};

export default TeslaInCarDashboard;