import React, { useState } from 'react';
import teslappstorelogo from './teslappstore.jpg'

const NewsPage = () => {
  const [newsItems,] = useState([
    {
      header: 'TeslAppStore is Live !!',
      imageUrl: teslappstorelogo,
      text: 'We finally made it online !!',
    },
    {
      header: 'Firmware Update history',
      imageUrl: 'https://via.placeholder.com/300x200',
      text: `You can now see whether a Supercharger has trailer-compatible stalls.
Tap a Supercharger locations pin on the map to see whether it is trailer-friendly.
If your vehicle is in Trailer Mode, trailer-friendly Supercharger locations appear at the top of the list.`,
    },
    {
      header: 'News Item 3',
      imageUrl: 'https://via.placeholder.com/300x200',
      text: 'This is the content of news item 3.',
    },
    {
      header: 'News Item 4',
      imageUrl: 'https://via.placeholder.com/300x200',
      text: 'This is the content of news item 4.',
    },
    // ... add 4 more news items with similar structure
  ]);

  

  return (
    <div className="p-4">
      <div className="text-xl font-bold mb-4">Tesla News</div>
      <div>
        {newsItems.map((item, index) => (
          <div key={index} className="mb-4">
            <h2 className="text-lg font-bold">{item.header}</h2>
            <img src={item.imageUrl} alt={item.header} className="mb-2" />
            <p>{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsPage;